/* Asoh Christian Mbe */
import { Link } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

const DemoNavbar = () => {
  return (
    <>
      <Navbar
        className="navbar-top navbar-horizontal navbar-dark"
        expand="md"
        style={{ backgroundColor: "#172B4D", padding: "5px" }}
      >
        <Container className="px-4">
          <NavbarBrand to="/" tag={Link} style={{ color: "black" }}>
            <img
              alt="TLSC"
              src={require("./../../assets/img/brand/main-logo.png")}
            />
          </NavbarBrand>
          <button className="navbar-toggler" id="navbar-collapse-main">
            <span className="navbar-toggler-icon" />
          </button>
          <UncontrolledCollapse navbar toggler="#navbar-collapse-main">
            <div className="navbar-collapse-header d-md-none">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/">
                    <img
                      alt="..."
                      src={require("./../../assets/img/brand/main-logo.png")}
                    />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button className="navbar-toggler" id="navbar-collapse-main">
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink
                  className="nav-link-icon"
                  to="/"
                  tag={Link}
                  style={{ color: "#5E72E4" }}
                >
                  <span className="nav-link-inner--text">HOME</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link-icon"
                  to="/services"
                  tag={Link}
                  style={{ color: "#5E72E4" }}
                >
                  <span className="nav-link-inner--text">SERVICES</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link-icon"
                  to="/about-us"
                  tag={Link}
                  style={{ color: "#5E72E4" }}
                >
                  <span className="nav-link-inner--text">ABOUT US</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link-icon"
                  to="/contact-us"
                  tag={Link}
                  style={{ color: "white" }}
                >
                  <i className="ni ni-key-25" />
                  <span className="nav-link-inner--text">CONTACT US</span>
                </NavLink>
              </NavItem>
            </Nav>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
};

export default DemoNavbar;
