/*         TLSC         - CYPHER  */
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";
import "./letstalk.css";

class LetsTalk extends React.Component {
  render() {
    return (
      <>
        <section className="section section-lg">
          <Container>
            <Row className="row-grid justify-content-center">
              <Col className="text-center" lg="8">
                <h2 className="display-3 thebg">
                  Need help with a project?
                  <span className="text-success">Let's talk</span>
                </h2>
                <p className="lead">
                  Cause if you do, it can be yours for FREE. Hit the button
                  below to navigate to contact Us
                </p>
                <div className="btn-wrapper">
                  <Button className="mb-3 mb-sm-0 bgcolor" color="#172B4D">
                    Lets Talk
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default LetsTalk;
