/*         TLSC         - CYPHER  */
import React, { useState } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DemoNavbar from "./../../components/Navbars/DemoNavbar.js";
import CardsFooter from "./../../components/Footers/CardsFooter.js";

// index page sections
import Download from "../IndexSections/Download.js";
import LetsTalk from "../IndexSections/LetsTalk.js";
import axios from "axios";

class ContactUs extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
    };
  }

  complete = async () => {
    const body = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      message: this.state.message,
    };
    const response = await axios.post(
      "https://techlabssolutions.com/backend/contact.php",
      body
    );
    if (response.data.status === 200) {
      alert("Message recieved");
    } else {
      alert("an error occured");
    }
  };
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <section
            className="section section-lg bg-gradient-default"
            style={{ backgroundColor: "#1F1C1C" }}
          >
            <Container className="pt-lg pb-300">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">Contact Us</h2>
                  <p className="lead text-white">
                    We are always here for all your inquiries, worries and
                    concerns. Just leave us a message and we will get back to
                    you as soon as possible.
                  </p>
                </Col>
              </Row>
              <Row className="row-grid mt-5">
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-email-83 text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Quick Response</h5>
                  <p className="text-white mt-3">
                    <a href="mailto:cocoyam91@gmail.com">
                      cocoyam91@gmail.com
                    </a>
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-bell-55 text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Response Team A</h5>
                  <p className="text-white mt-3">
                    <a href="mailto:cocoyam91@gmail.com">
                      cocoyam91@gmail.com
                    </a>
                  </p>
                </Col>
                <Col lg="4">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-send text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Response Team B</h5>
                  <p className="text-white mt-3">
                    <a href="mailto:cocoyam91@gmail.com">
                      cocoyam91@gmail.com
                    </a>
                  </p>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Get in touch</h4>
                      <p className="mt-0">
                        Tell us a bit about yourself and what you'd like to chat
                        about. Someone from Ground Control will be in touch
                        shortly.
                      </p>
                      <FormGroup
                        className={classnames("mt-5", {
                          focused: this.state.nameFocused,
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-user-run" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Your name"
                            type="text"
                            onFocus={(e) =>
                              this.setState({ nameFocused: true })
                            }
                            onBlur={(e) =>
                              this.setState({ nameFocused: false })
                            }
                            value={this.state.name}
                            onChange={(e) =>
                              this.setState({ name: e.target.value })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.emailFocused,
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email address"
                            type="email"
                            onFocus={(e) =>
                              this.setState({ emailFocused: true })
                            }
                            onBlur={(e) =>
                              this.setState({ emailFocused: false })
                            }
                            value={this.state.email}
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.phoneFocused,
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Phone"
                            type="tel"
                            onFocus={(e) =>
                              this.setState({ phoneFocused: true })
                            }
                            onBlur={(e) =>
                              this.setState({ phoneFocused: false })
                            }
                            value={this.state.phone}
                            onChange={(e) =>
                              this.setState({ phone: e.target.value })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Input
                          className="form-control-alternative"
                          cols="80"
                          name="name"
                          placeholder="Type a message..."
                          rows="4"
                          type="textarea"
                          value={this.state.message}
                          onChange={(e) =>
                            this.setState({ message: e.target.value })
                          }
                        />
                      </FormGroup>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                          onClick={this.complete}
                        >
                          Let's Connect
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
          <LetsTalk />
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default ContactUs;
