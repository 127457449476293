/*         TLSC         - CYPHER  */
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DemoNavbar from "../../components/Navbars/DemoNavbar.js";
import CardsFooter from "../../components/Footers/CardsFooter.js";

// index page sections
import Download from "../IndexSections/Download.js";

export default function About() {
  return (
    <>
      <DemoNavbar />
      <section className="section bg-secondary">
        <Container>
          <Row className="row-grid align-items-center">
            <Col md="6">
              <Card className="bg-default shadow border-0">
                <CardImg
                  alt="..."
                  src={require("./../../assets/img/about.png")}
                  top
                />
                <blockquote className="card-blockquote">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svg-bg"
                    preserveAspectRatio="none"
                    viewBox="0 0 583 95"
                  >
                    <polygon
                      className="fill-default"
                      points="0,52 583,95 0,95"
                    />
                    <polygon
                      className="fill-default"
                      opacity=".2"
                      points="0,42 583,95 683,0 0,95"
                    />
                  </svg>
                  <h4 className="display-3 font-weight-bold text-white">
                    Unique approach
                  </h4>
                  <p className="lead text-italic text-white">
                    Any good agency can deliver on the challenge you bring them.
                    But, a strategic agency works with you to uncover the true
                    reason behind your challenge, the one that is blocking your
                    business's growth.
                  </p>
                </blockquote>
              </Card>
            </Col>
            <Col md="6">
              <div className="pl-md-5">
                <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                  <i className="ni ni-settings" />
                </div>
                <h3>Combination of service</h3>
                <p className="lead">
                  Consistency breeds confidence — make every touchpoint your
                  audience experiences the same with our suite of service
                  offerings. From Creative, to Strategy, Development and
                  Marketing, take care of your holistic digital marketing needs
                  in one place.
                </p>
                <p>
                  Just looking for one, specific digital service? You can invest
                  in one at a time, obligation-free.
                </p>
                <p>
                  At TLSC, you’ll benefit from our four-phased approach. This
                  method shows us your whole organization, allowing us to
                  identify the best strategy, the tactics that will bring you
                  success, and how you can improve upon that success.
                </p>

                <a
                  className="font-weight-bold text-warning mt-5"
                  href="#TLSC"
                  onClick={(e) => e.preventDefault()}
                >
                  With us, your brand is a step closer to it's success
                </a>
              </div>
            </Col>
          </Row>
          <Row className="row-grid mt-5" style={{ backgroundColor: "grey" }}>
            <h3 style={{ width: "100%", textAlign: "Center" }}>About Team</h3>
            <Col lg="4">
              <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                <i className="ni ni-email-83 text-primary" />
              </div>
              <h5 className="text-white mt-3">Asoh Christian Mbe - CEO</h5>
              <p className="text-white mt-3">
                <a href="mailto:cocoyam91@gmail.com">cocoyam91@gmail.com</a>
                <br />
                <a href="tel:+237652856974">+237 680 159 182</a>
              </p>
            </Col>
            <Col lg="4">
              <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                <i className="ni ni-bell-55 text-primary" />
              </div>
              <h5 className="text-white mt-3">Response Team A</h5>
              <p className="text-white mt-3">
                <a href="mailto:info@techlabssolutions.com">
                  info@techlabssolutions.com
                </a>
                <br />
                <a href="tel:+237683366728">+237 683 366 728</a>
              </p>
            </Col>
            <Col lg="4">
              <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                <i className="ni ni-send text-primary" />
              </div>
              <h5 className="text-white mt-3">Response Team B</h5>
              <p className="text-white mt-3">
                <a href="mailto:cocoyam91@gmail.com">
                  cocoyam91@gmail.com
                </a>
                <br />
                <a href="tel:+237670619985">+237 670 619 985</a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <CardsFooter />
    </>
  );
}
