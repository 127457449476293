import React from "react";
import classes from "./css_modules/time.module.css";

export default function Timeline() {
  return (
    <section className={classes.body}>
      <h1
        style={{
          textAlign: "center",
          fontWeight: 600,
          color: "white",
        }}
      >
        How we do it
      </h1>
      <div class={classes.timeline}>
        <div class={[`${classes.container}`, `${classes.left}`].join(" ")}>
          <div class={classes.content}>
            <h2>Customer Experiences</h2>
            <p>
              Exceptional user experiences feel intuitive. We get close to your
              customers to find out what they need. Then design for maximum
              adoption, engagement and enjoyment.
            </p>
          </div>
        </div>
        <div class={[`${classes.container}`, `${classes.right}`].join(" ")}>
          <div class={classes.content}>
            <h2>Engineering and Design</h2>
            <p>
              World-class products hit key metrics and win markets. We
              architect, concept, prototype and test to discover the right thing
              to build to meet those goals.
            </p>
          </div>
        </div>
        <div class={[`${classes.container}`, `${classes.left}`].join(" ")}>
          <div class={classes.content}>
            <h2>Product</h2>
            <p>
              Successful products satisfy user needs, rely on intelligent
              technology choices and focus on business outcomes. We help you
              achieve product driven growth.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
