/*         TLSC         - CYPHER  */
import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import sideImage from "./../../assets/img/ill/ill-2.svg";

function SectionOneReverse(props) {
  return (
    <>
      <section className="section section-lg section-shaped bg-transparent">
        <div className="shape shape-style-2 bg-transparent">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <Container className="py-md">
          <Row className="row-grid justify-content-between align-items-center">
            <Col className="mb-lg-auto" lg="5">
              <div className="transform-perspective-right">
                <Card className="bg-secondary shadow border-0">
                  <img alt="grouped images" src={props.image} />
                </Card>
              </div>
            </Col>
            <Col lg="6">
              <h3 className="display-3 text-black">
                {props.he}
                <span className="text-black">{props.sh}</span>
              </h3>
              <p className="lead text-black">{props.para}</p>
              <div className="btn-wrapper">
                <Button color="success" to={props.btl} tag={Link}>
                  {props.bt}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
        {/* SVG separator */}
        <div className="separator separator-bottom separator-skew">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-white" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </section>
    </>
  );
}

export default SectionOneReverse;
