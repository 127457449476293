/*         TLSC         - CYPHER  */
import React from "react";
import ReactDOM from "react-dom";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/scss/argon-design-system-react.scss?v1.1.0";

import Index from "./views/Index.js";
import Landing from "./views/examples/Landing.js";
import Login from "./views/examples/Login.js";
import Profile from "./views/examples/Profile.js";
import Register from "./views/examples/Register.js";
import ContactUs from "./views/examples/ContactUs";
import Services from "./views/examples/Services";
import About from "./views/examples/About";

ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/" exact render={(props) => <Index {...props} />} />
      <Route
        path="/landing-page"
        exact
        render={(props) => <Landing {...props} />}
      />
      <Route
        path="/login-page"
        exact
        render={(props) => <Login {...props} />}
      />
      <Route
        path="/profile-page"
        exact
        render={(props) => <Profile {...props} />}
      />
      <Route
        path="/register-page"
        exact
        render={(props) => <Register {...props} />}
      />
      <Route
        path="/services"
        exact
        render={(props) => <Services {...props} />}
      />
      <Route path="/contact-us" exact render={(props) => <ContactUs />} />
      <Route path="/about-us" exact render={(props) => <About />} />

      <Redirect to="/" />
    </Switch>
  </Router>,
  document.getElementById("root")
);
