/*         TLSC         - CYPHER  */
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "./../components/Navbars/DemoNavbar.js";
import CardsFooter from "./../components/Footers/CardsFooter.js";

// index page sections
import Hero from "./IndexSections/Hero.js";
import Buttons from "./IndexSections/Buttons.js";
import Inputs from "./IndexSections/Inputs.js";
import CustomControls from "./IndexSections/CustomControls.js";
import Menus from "./IndexSections/Menus.js";
import Navbars from "./IndexSections/Navbars.js";
import Tabs from "./IndexSections/Tabs.js";
import Progress from "./IndexSections/Progress.js";
import Pagination from "./IndexSections/Pagination.js";
import Pills from "./IndexSections/Pills.js";
import Labels from "./IndexSections/Labels.js";
import Alerts from "./IndexSections/Alerts.js";
import Typography from "./IndexSections/Typography.js";
import Modals from "./IndexSections/Modals.js";
import Datepicker from "./IndexSections/Datepicker.js";
import TooltipPopover from "./IndexSections/TooltipPopover.js";
import Carousel from "./IndexSections/Carousel.js";
import Icons from "./IndexSections/Icons.js";
import Login from "./IndexSections/Login.js";
import Download from "./IndexSections/Download.js";
import "./index.css";
import SectionOne from "./IndexSections/SectionOne.js";
import SectionTwo from "./IndexSections/SectionTwo.js";
import LetsTalk from "./IndexSections/LetsTalk.js";
import MyHero from "./IndexSections/MyHero.js";
import Timeline from "./IndexSections/Timeline.js";
import SectionOneReverse from "./IndexSections/SectionOneReverse.js";

class Index extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <MyHero />
          <section className="section section-lg bg-transparent">
            <Container className="pt-20 pb-20">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-black">WHY US</h2>
                  <p className="lead text-black">
                    We guide game-changing companies, across platforms & places,
                    through agile design & digital experience. We make waves™
                  </p>
                </Col>
              </Row>
              <Row className="row-grid mt-5 center-why-us">
                <Col lg="3" className="bg-gradient-default m-1">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-settings text-primary" />
                  </div>
                  <h5 className="text-white mt-3">
                    Return On Design & Digital (RODD)
                  </h5>
                  <p className="text-white mt-3">
                    We believe in creating an RODD: where your brand can
                    monetize and become highly marketable from our
                    cross-collective design and digital strategies.
                  </p>
                </Col>
                <Col lg="3" className="bg-gradient-default m-1">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-ruler-pencil text-primary" />
                  </div>
                  <h5 className="text-white mt-3">No Scope Creep</h5>
                  <p className="text-white mt-3">
                    Change happens, we get it. Knowing that, we work with you
                    and not against you. Everything is discussed and agreed so
                    we avoid friction points.
                  </p>
                </Col>
                <Col lg="3" className="bg-gradient-default m-1">
                  <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                    <i className="ni ni-atom text-primary" />
                  </div>
                  <h5 className="text-white mt-3">Ditch The Line</h5>
                  <p className="text-white mt-3">
                    You get priority, every day and always. No waiting for
                    others in queue before you - you’re our first priority.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
          <SectionTwo />
          <SectionOne
            he="What makes TECH labs, TECH labs"
            sh=""
            para="We're made up of some of the brightest minds on the web who humanize digital marketing. See how we do things and why you'll want to partner with us"
            bt="Discover more"
            btl="/contact-us"
            image={require("./../assets/img/ill/ill-2.svg").default}
          />

          <Timeline />
          <LetsTalk />
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Index;
