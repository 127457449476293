/*         TLSC         - CYPHER  */
import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledCarousel } from "reactstrap";
import "./MyHero.css";

const items = [
  {
    src: require("./../../assets/img/one.png"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("./../../assets/img/two.png"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("./../../assets/img/three.png"),
    altText: "",
    caption: "",
    header: "",
  },
];

class MyHero extends React.Component {
  render() {
    return (
      <>
        <section className="section section-shaped">
          <div className="shape hero">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="py-md">
            <Row className="justify-content-between align-items-center">
              <Col className="mb-5 mb-lg-0" lg="5">
                <h1 className="text-white font-weight-light heading">
                  CREATE YOUR BRAND
                </h1>
                <p className="lead text-white mt-4">
                  We design and build platforms, programmes and products, for
                  the world's most ambitious brands. Get your brand up and going
                  with the best expirience ever, stress free and awesome with{" "}
                  <span style={{ color: "blue" }}>TLSC</span>.
                </p>
                <Button
                  className="btn-white mt-4"
                  color="default"
                  href="/#/contact-us"
                >
                  Contact Us
                </Button>
              </Col>
              <Col className="mb-lg-auto rightshape" lg="6">
                <div className="rounded shadow-lg overflow-hidden transform-perspective-right">
                  <UncontrolledCarousel items={items} />
                </div>
              </Col>
            </Row>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
      </>
    );
  }
}

export default MyHero;
