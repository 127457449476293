/*         TLSC         - CYPHER  */
import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import sideImage from "./../../assets/img/ill/ill-2.svg";
import "./sectiontwo.css";

class SectionTwo extends React.Component {
  state = {};
  render() {
    return (
      <>
        <section className="section section-lg section-shaped bg-transparent">
          <div className="shape shape-style-2 bg-transparent">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="py-md">
            <Row className="row-grid justify-content-between align-items-center">
              <Col className="mb-lg-auto addbg" lg="6">
                <Row className="aligner">
                  <Col className="coledit">
                    <Col className="bg-gradient-secondary m-1 transform-perspective-right cardleft">
                      <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="ni ni-app text-primary" />
                      </div>
                      <h5 className="text-black mt-3">Logo Design</h5>
                      <p className="text-black mt-3">
                        Get the perfect logo for your brand that will sell its
                        unique identity online.
                      </p>
                    </Col>
                    <Col className="bg-gradient-secondary m-1 transform-perspective-left cardleft">
                      <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="ni ni-building text-primary" />
                      </div>
                      <h5 className="text-black mt-3">Website Design</h5>
                      <p className="text-black mt-3">
                        Showcase your services in an orderly and beautiful
                        manner to potential clients allover the world and drive
                        sales like never before.
                      </p>
                    </Col>
                  </Col>
                  <Col className="coledit">
                    <Col className="bg-gradient-secondary m-1 transform-perspective-right cardleft">
                      <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="ni ni-square-pin text-primary" />
                      </div>
                      <h5 className="text-black mt-3">Digital Marketing</h5>
                      <p className="text-black mt-3">
                        Advertise your services to just the right audience,
                        convert potential clients to clients and reach more
                        people in no time and with a considerable budget.
                      </p>
                    </Col>
                  </Col>
                </Row>
                {/* <div className="transform-perspective-right">
                  <Card className="bg-secondary shadow border-0">
                    <img alt="grouped images" src={sideImage} />
                  </Card>
                </div> */}
              </Col>
              <Col lg="4">
                <h3 className="display-3 text-black">Find clarity in chaos</h3>
                <p className="lead text-black">
                  TECH labs is a full-service marketing agency that's been
                  purpose-built to help organizations thrive in an increasingly
                  complex landscape.
                </p>
                <div className="btn-wrapper">
                  <Button color="success" to="/contact-us" tag={Link}>
                    Let's talk
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </section>
      </>
    );
  }
}

export default SectionTwo;
